import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col, FormControl } from "react-bootstrap";

import { Button, Section, Box, Text, Input, MainTitle } from "../../components/Core";
import { Formik, Form } from 'formik';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PageWrapper from "../../components/PageWrapper";
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "./../../utils/helperFn";
import * as Yup from "yup";
import axios from "axios";
import { Modal } from 'react-bootstrap'

import API from './../../api/api';
import APINO from './../../api/api-no';

const FormStyled = styled.form``;

const submitSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('This field is required'),
    lastName: Yup.string()
        .required('This field is required'),
    email: Yup.string()
        .email('Invalid email')
        .required('This field is required'),
    subject: Yup.string()
        .required('This field is required'),
    messageText: Yup.string()
        .required('This field is required'),
});

function validateFeedback(feedback) {
    if (feedback) {
        return <FormControl.Feedback
            type="invalid"
            className="pl-1">{feedback}
        </FormControl.Feedback>
    } else {
        return <></>
    }
}

const Contact = () => {

    const data = useStaticQuery(graphql`
        query strapiContactQuery {
            strapiContactPage {
                title
                subtitle_1
                subtitle_2
                enquiry_notice
                title_2
                text_1
                text_2
                text_3
            }
            strapiPageHeadings(slug: {eq: "contact"}) {
                h1
            }
        }
    `);

    const pageData = getProperty(data, 'strapiContactPage');
    const headingsData = getProperty(data, 'strapiPageHeadings');
    // Modal Success
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [alertVariant, setAlertVariant] = useState('success')
    const closeModal = () => {
        setIsModalOpen(false)
    }
    return (
        <>
            <GoogleReCaptchaProvider reCaptchaKey="6LdC-H4aAAAAAN9oY90s98Xr3xr6RKQr1pX7FBxB">
                <PageWrapper footerDark>
                    <Section>
                        <div className="pt-5" />
                        <Container>
                            <Row className="justify-content-center text-center">
                                <Col lg="8">
                                    <div className="banner-content">
                                        <MainTitle>{headingsData.h1}</MainTitle>
                                    </div>
                                    <div>
                                        <Text>
                                            {pageData.subtitle_1}
                                            <br className="d-none d-md-block" />
                                            <div dangerouslySetInnerHTML={{ __html: pageData.subtitle_2 }}></div>
                                        </Text>
                                    </div>
                                    <Text dangerouslySetInnerHTML={{ __html: pageData.enquiry_notice }}></Text>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col sm={12} md={8} className="order-lg-1 pt-5 mt-4">
                                    <div>
                                        <FormStyled>
                                            <Formik
                                                validateOnChange={false}
                                                validateOnBlur={false}
                                                initialValues={{
                                                    firstName: '',
                                                    lastName: '',
                                                    email: '',
                                                    subject: '',
                                                    messageText: ''
                                                }}
                                                validationSchema={submitSchema}
                                                onSubmit={async (values, { resetForm, setSubmitting }) => {
                                                    let request = {
                                                        content: values.messageText,
                                                        expire_select: "never",
                                                        force_author: values.email,
                                                        is_private: 0,
                                                        object_type: "ticket",
                                                        ownership: "helpdesk",
                                                        responsible_id: 0,
                                                        title: values.subject,
                                                        properties: {
                                                            first_name: values.firstName,
                                                            last_name: values.lastName
                                                        }
                                                    };

                                                    try {
                                                        APINO.post(`/CrmModule/v1.0/ticket/create`, request).then((resp) => {
                                                            // After successful submission, reset the form fields
                                                            resetForm();
                                                            setSubmitting(false)
                                                            setIsModalOpen(true)
                                                            setAlertVariant('success')
                                                        }).catch(error => {
                                                            alert('Error')
                                                        });
                                                    } catch (e) {
                                                        console.log(e)
                                                    }
                                                }}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    handleChange,
                                                    setFieldValue,
                                                }) => (
                                                    <Form>

                                                        {/* First name */}
                                                        <Box mb={4}>
                                                            <Input
                                                                type=""
                                                                name="firstName"
                                                                placeholder="First name"
                                                                value={values.firstName}
                                                                onChange={handleChange}
                                                                className={errors.firstName ? 'is-invalid' : 'is-valid'}
                                                            />
                                                            {validateFeedback(errors.firstName)}
                                                        </Box>

                                                        {/* Last name */}
                                                        <Box mb={4}>
                                                            <Input
                                                                type=""
                                                                name="lastName"
                                                                placeholder="Last name"
                                                                value={values.lastName}
                                                                onChange={handleChange}
                                                                className={errors.lastName ? 'is-invalid' : 'is-valid'}
                                                            />
                                                            {validateFeedback(errors.lastName)}
                                                        </Box>

                                                        {/* E-mail address */}
                                                        <Box mb={4}>
                                                            <Input
                                                                type="email"
                                                                name="email"
                                                                placeholder="E-mail address"
                                                                value={values.email}
                                                                onChange={handleChange}
                                                                className={errors.email ? 'is-invalid' : 'is-valid'}
                                                            />
                                                            {validateFeedback(errors.email)}
                                                        </Box>

                                                        {/* Message subject */}
                                                        <Box mb={4}>
                                                            <Input
                                                                type=""
                                                                name="subject"
                                                                placeholder="Message subject"
                                                                value={values.subject}
                                                                onChange={handleChange}
                                                                className={errors.subject ? 'is-invalid' : 'is-valid'}
                                                            />
                                                            {validateFeedback(errors.subject)}
                                                        </Box>

                                                        {/* Message */}
                                                        <Box mb={4}>
                                                            <Input
                                                                type="text"
                                                                as="textarea"
                                                                rows={4}
                                                                name="messageText"
                                                                placeholder="Write your message here"
                                                                value={values.messageText}
                                                                onChange={handleChange}
                                                                className={errors.messageText ? 'is-invalid' : 'is-valid'}
                                                            />
                                                            {validateFeedback(errors.messageText)}
                                                        </Box>
                                                        {/* Submit button */}
                                                        <div className="pt-4 text-center">
                                                            <Button width="60%" type="submit" borderRadius={10}>
                                                                Send Message
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </FormStyled>

                                        {/*    MODAL */}
                                        <Modal centered show={isModalOpen} onHide={closeModal}>
                                            <Modal.Header>
                                                <Modal.Title>Congratulations!</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className={`alert alert-${alertVariant}`}>Your message has been successfully submitted.</div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={closeModal}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        {/*    MODAL */}

                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center text-center">
                                <Col lg="8">
                                    <h3 className="color-black mt-5">{pageData.title_2}</h3>
                                    <br />
                                    <Text dangerouslySetInnerHTML={{ __html: pageData.text_1 }}></Text>
                                    <br />
                                    <Text dangerouslySetInnerHTML={{ __html: pageData.text_3 }}></Text>
                                    <br />
                                </Col>
                            </Row>
                        </Container>
                    </Section>
                </PageWrapper>
            </GoogleReCaptchaProvider>
        </>
    );
};

export default Contact;
